<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form
          ref="enrollmentForm"
          v-model="isValidForm"
          @submit.prevent="submit"
        >
          <v-row>
            <v-col>
              <h1 class="headline black--text font-weight-black mb-5">
                Pharmacy Enrollment
              </h1>
              <div class="subtitle-1 black--text font-weight-medium">
                Please fill the form bellow to enable your pharmacy for installation.
              </div>

              <!-- <div class="subtitle-1 black--text font-weight-medium">
                Please click the image below to download PharmaGuide's Remote Support Client.
              </div>
              <div class="subtitle-1 black--text font-weight-medium">
                Simply double click and follow the instructions to complete installation.
              </div>
              <div class="subtitle-1 black--text font-weight-medium">
                PharmaGuide will be contacting you shortly to finalize your pharmacy's connection to Phox Cloud.
              </div> -->
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col>
              <v-btn
                depressed
                color="primary"
                :href="clientSupportLink"
                target="_blank"
              >
                <v-icon>{{ icons.mdiDownload }}</v-icon>&nbsp;Download Remote Support Client
              </v-btn>
            </v-col>
          </v-row> -->

          <!-- <v-row>
            <v-col>
              <h2 class="headline black--text font-weight-medium mt-10">
                Pharmacy Address
              </h2>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <p class="body-1 black--text  font-weight-medium">
                        Search your pharmacy name or accreditation number:
                      </p>
                      <v-autocomplete
                        v-model="selectedPharmacy"
                        placeholder="e.g. Pharmassist, Pharmasmart, etc."
                        return-object
                        item-text="name"
                        item-value="accNumber"
                        outlined
                        filled
                        hide-no-data
                        no-filter
                        clearable
                        autofocus
                        :items.sync="pharmacyAddressResults"
                        :search-input.sync="search"
                        :prepend-icon="icons.mdiDatabaseSearch"
                        @change="setSelectedPharmacy"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title><strong>{{ data.item.name }}</strong> #{{ data.item.accNumber }}</v-list-item-title>
                            <v-list-item-subtitle><small>{{ data.item.address }}</small></v-list-item-subtitle>
                            <v-divider></v-divider>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="pharmacy.address.unit"
                        outlined
                        :background-color="bgc(pharmacy.address.unit)"
                        label="Pharmacy Unit"
                        hint="Optional - e.g. 10, #20, Unit 120"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="pharmacy.address.streetNumber"
                        outlined
                        :background-color="bgc(pharmacy.address.streetNumber)"
                        label="Pharmacy Street Number"
                        hint="e.g. 1234"
                        required
                        persistent-hint
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="pharmacy.address.streetName"
                        outlined
                        :background-color="bgc(pharmacy.address.streetName)"
                        label="Pharmacy Street Name"
                        hint="e.g. Main St E, Yonge street west"
                        required
                        persistent-hint
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col
                      cols="6"
                      xs="12"
                    >
                      <v-text-field
                        v-model="pharmacy.address.city"
                        outlined
                        :background-color="bgc(pharmacy.address.city)"
                        label="City"
                        hint="e.g. Toronto, Ottawa, etc."
                        required
                        persistent-hint
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="3"
                      xs="12"
                    >
                      <v-select
                        v-model="pharmacy.address.state"
                        :background-color="bgc(pharmacy.address.state)"
                        outlined
                        label="Province"
                        item-text="name"
                        item-value="value"
                        :items="provinceOptions"
                        required
                        persistent-hint
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="3"
                      xs="12"
                    >
                      <v-text-field
                        v-model="pharmacy.address.code"
                        outlined
                        :background-color="bgc(pharmacy.address.code)"
                        label="Postal Code"
                        hint="e.g. A1A 2B2"
                        required
                        persistent-hint
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col>
              <h2 class="headline black--text font-weight-medium mt-10">
                Pharmacy information
              </h2>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col col="4">
              <v-text-field
                v-model="pharmacy.email"
                outlined
                :background-color="bgc(pharmacy.email)"
                label="Pharmacy Email"
                required
                persistent-hint
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col col="4">
              <v-text-field
                v-model="pharmacy.name"
                outlined
                :background-color="bgc(pharmacy.name)"
                label="Pharmacy Name"
                required
                persistent-hint
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col col="4">
              <v-text-field
                v-model="pharmacy.accNumber"
                outlined
                :background-color="bgc(pharmacy.accNumber)"
                label="Pharmacy Accreditation Number"
                required
                persistent-hint
                :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                :background-color="bgc(pharmacy.phone)"
                label="Pharmacy Phone Number"
                required
                persistent-hint
                maxlength="14"
                :rules="rules.required"
                :value="formattedPhone(pharmacy.phone || '')"
                @input="updatePhone('phone', $event)"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                :background-color="bgc(pharmacy.fax)"
                label="Pharmacy Fax Number"
                required
                persistent-hint
                maxlength="14"
                :rules="rules.required"
                :value="formattedPhone(pharmacy.fax || '')"
                @input="updatePhone('fax', $event)"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- vuetify subheader -->
          <v-row>
            <v-col>
              <h2 class="headline black--text font-weight-medium mt-10">
                Pharmacy workstation information
              </h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                v-model="pharmacy.metaData.osCredentials.type"
                :background-color="bgc(pharmacy.metaData.osCredentials.type)"
                outlined
                :items="workstationOptions"
                label="Workstation dispense system type"
                required
                persistent-hint
                :rules="rules.required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="pharmacy.metaData.osCredentials.username"
                outlined
                :background-color="bgc(pharmacy.metaData.osCredentials.username)"
                label="Windows Server Administrator Username"
                required
                persistent-hint
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="pharmacy.metaData.osCredentials.password"
                outlined
                :background-color="bgc(pharmacy.metaData.osCredentials.password)"
                label="Windows Server Administrator Password"
                required
                persistent-hint
                :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>

          <div v-if="pharmacy.metaData.osCredentials.type === 'Nexxsys'">
            <v-row>
              <v-col>
                <v-alert
                  outlined
                  text
                  type="info"
                >
                  Please create a new user in nexxsys system to complete your installations ( This will be only be used for Phox )
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="pharmacy.db.username"
                  outlined
                  :background-color="bgc(pharmacy.db.username)"
                  label="Nexxsys (PharmaClik) Username"
                  required
                  persistent-hint
                  :rules="rules.required"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  v-model="pharmacy.db.password"
                  outlined
                  :background-color="bgc(pharmacy.db.password)"
                  label="Nexxsys (PharmaClik) password"
                  required
                  persistent-hint
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col>
              <v-text-field
                v-model="pharmacy.referralcode"
                outlined
                :background-color="bgc(pharmacy.referralcode)"
                persistent-hint
                label="Referral Code"
                hint="Optional"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-checkbox
                v-model="TCAgreement"
                required
                persistent-hint
                class="shrink"
                :rules="rules.required"
              >
                <template v-slot:label>
                  I agree to&nbsp;<a
                    target="_blank"
                    href="https://pharmaguide.ca/phox-terms-of-service"
                    @click.stop
                  >terms and conditions</a>.
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-show="submitResponse.type !== 'success'"
                class="my-2"
                color="primary"
                width="30vw"
                :loading="isSubmitting"
                type="submit"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="submitResponse.type">
            <v-col>
              <v-alert
                v-show="submitResponse.type == 'error'"
                outlined
                text
                :type="submitResponse.type"
                v-html="submitResponse.message"
              ></v-alert>

              <v-alert
                v-show="submitResponse.type == 'success'"
                outlined
                text
                type="info"
              >
                <v-row>
                  <v-col>
                    <!-- <h1 class="headline black--text font-weight-black mb-5">
                      Pharmacy Enrollment
                    </h1> -->
                    <div class="subtitle-1 black--text font-weight-medium">
                      Please download and install our remote support client on your main station.
                    </div>
                    <div class="subtitle-1 black--text font-weight-medium">
                      A member from our support team will be in contact soon to complete your installation.
                    </div>
                    <!-- <div class="subtitle-1 black--text font-weight-medium">
                      PharmaGuide will be contacting you shortly to finalize your pharmacy's connection to Phox Cloud.
                    </div> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="d-flex"
                  >
                    <v-btn
                      depressed
                      color="success"
                      :href="clientSupportLink"
                      target="_blank"
                    >
                      <v-icon>{{ icons.mdiDownload }}</v-icon>&nbsp;Download Remote Support Client
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="subtitle-1 black--text font-weight-medium">
                      If you have any questions, please contact us at  <a href="mailto:support@pharmaguide.ca">support@pharmaguide.ca</a>.
                    </div>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mdiDatabaseSearch, mdiDownload } from '@mdi/js'
import { VueMaskDirective, VueMaskFilter } from 'v-mask'
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapsService, pharmacyService } from '@/render/api/zarya'
import { debounce } from 'lodash'
import Vue from 'vue'

export default {
  name: 'Enrollment',
  filters: {
    VueMask: VueMaskFilter,
  },
  directives: {
    Mask: VueMaskDirective,
  },
  data() {
    // const token = await this.$auth.getTokenSilently()
    // this.URLmade = `https://hipaa.jotform.com/202798100388055?token=${token}&owneremail=${this.$auth.user.email}&auth0Id=${this.$auth.user.sub}`

    return {
      geocoderInstance: null,
      search: '',
      selectedPharmacy: null,
      isValidForm: false,
      TCAgreement: false,
      isLoadingPharmacySearch: false,
      isSubmitting: false,
      submitResponse: {
        type: null,
        message: null,
      },
      pharmacy: {
        name: '',
        address: {},
        db: {},
        metaData: {
          osCredentials: {},
          stripe: {},
        },
      },
      messages: {
        enrollment: {
          success: 'Your enrollment has been submitted successfully.<br /> PharmaGuide will be contacting you shortly to finalize your pharmacy\'s connection to Phox Cloud.',
          error: 'There was an error submitting your enrollment. for further help, please contact us at <a href="mailto:support@pharmaguide.ca">support@pharmaguide.ca</a>',
        },
      },
      pharmacyAddressResults: [],
      rules: {
        required: [value => !!value || 'Required input.'],
      },
      icons: {
        mdiDownload,
        mdiDatabaseSearch,
      },
      clientSupportLink:
        'https://pharma-guide-portal.nyc3.digitaloceanspaces.com/SupportClient/Splashtop_Streamer_Windows_DEPLOY_INSTALLER_v3.4.2.2_TSXRL5LRXSH7.exe',
      workstationOptions: ['Nexxsys', 'Kroll', 'Fillware'],
      provinceOptions: [
        {
          name: 'Alberta',
          value: 'AB',
        },
        {
          name: 'British Columbia',
          value: 'BC',
        },
        {
          name: 'Manitoba',
          value: 'MB',
        },
        {
          name: 'New Brunswick',
          value: 'NB',
        },
        {
          name: 'Newfoundland and Labrador',
          value: 'NL',
        },
        {
          name: 'Northwest Territories',
          value: 'NT',
        },
        {
          name: 'Nova Scotia',
          value: 'NS',
        },
        {
          name: 'Nunavut',
          value: 'NU',
        },
        {
          name: 'Ontario',
          value: 'ON',
        },
        {
          name: 'Prince Edward Island',
          value: 'PE',
        },
        {
          name: 'Quebec',
          value: 'QC',
        },
        {
          name: 'Saskatchewan',
          value: 'SK',
        },
        {
          name: 'Yukon',
          value: 'YT',
        },
      ],

      // email: this.$auth.user.email,
    }
  },
  created() {
  },
  watch: {
    search: debounce(function search(pharmacyName) {
      if (!pharmacyName) {
        return // do nothing
      }

      // Items have already been requested
      if (
        this.isLoadingPharmacySearch // when its loading
        || this.selectedPharmacy?.name === pharmacyName // when selected and input is the same
      ) {
        return // do nothing
      }

      this.isLoadingPharmacySearch = true

      // elastic needs double quotes for precise search
      this.getPharmacy(`"${pharmacyName}"`)
        .then(resp => {
          const pharmacyResults = resp.results.map(pharmacy => ({
            name: pharmacy.pharmacy_name.raw,
            address: pharmacy.address.raw,
            city: pharmacy.city.raw,
            code: pharmacy.postal.raw,
            phone: pharmacy.phone.raw,
            fax: pharmacy.fax.raw,
            accNumber: pharmacy.accreditation_number.raw,
          }))
          this.pharmacyAddressResults = pharmacyResults
        })
        .catch(err => {
          // do nothing
          // console.log(err)
        })
        .finally(() => {
          this.isLoadingPharmacySearch = false
        })
    }, 500),
  },
  methods: {
    bgc(value) {
      return value ? 'red' : 'warning'
    },
    updatePhone(prop, value) {
      Vue.set(this.pharmacy, prop, value)
    },
    formattedPhone(number) {
      return VueMaskFilter(number, '(###) ###-####')
    },
    async setSelectedPharmacy() {
      if (!this.selectedPharmacy) { // prevent null reference
        return
      }

      this.pharmacy.name = this.selectedPharmacy.name
      this.pharmacy.accNumber = this.selectedPharmacy.accNumber
      this.pharmacy.phone = this.selectedPharmacy.phone
      this.pharmacy.fax = this.selectedPharmacy.fax

      let address
      try {
        address = await this.getAddressDetails(
          `${this.pharmacy.name}, ${this.selectedPharmacy.address}, ${this.selectedPharmacy.city}, ${this.selectedPharmacy.code}, Canada`,
        )
      } catch (err) {
        // failed to retrieve address from google maps, use the one from the search
        address = {
          unit: '',
          streetNumber: '',
          streetName: this.selectedPharmacy.address,
          city: this.selectedPharmacy.city,
          code: this.selectedPharmacy.code,
          state: 'ON', // set statically due that autocomplete search is only for Ontario
          country: 'CA', // set statically due that autocomplete search is only for Canada
        }
      }

      Vue.set(this.pharmacy, 'address', address)
    },
    async getAddressDetails(address) {
      try {
      // get pharmacy address parsing from google maps
        return await mapsService.geocode({ address })
      } catch (err) {
        console.error('ERR<MapService>:', err)
      }

      return null
    },
    async submit() {
      this.$refs.enrollmentForm.validate()

      if (!this.isValidForm) {
        return
      }

      this.isSubmitting = true
      this.submitError = null

      const accessToken = await this.$auth.getTokenSilently()

      try {
        await pharmacyService.enrollment(
          accessToken,
          this.$auth.user.sub.replace('auth0|', ''),
          this.pharmacy,
        )
        this.submitResponse = {
          type: 'success',
          message: this.messages.enrollment.success,
        }
      } catch ({ data }) {
        this.submitResponse = {
          type: 'error',
          message: data.message || this.messages.enrollment.error,
        }
      }

      this.isSubmitting = false
    },
    async getPharmacy(pharmacyName) {
      return pharmacyService.search(pharmacyName)
    },
  },
}
</script>
<style lang="scss" scoped>
.v-input input:required + label::after {
    content: "*";
    color: red;
}
</style>
