/* eslint-disable import/order */
import { GOOGLE } from '@/config'
import { Loader } from '@googlemaps/js-api-loader'

// eslint-disable-next-line no-unused-vars
let googleInstance
let geocoderInstance

const getGoogleInstance = async (options = { version: 'weekly', libraries: ['places'] }) => {
  if (!googleInstance) {
    googleInstance = await new Loader({
      apiKey: GOOGLE.MAPS_API_KEY,
      version: options.version,
      libraries: options.libraries,
    }).load()
  }

  return googleInstance
}
const getGeoCoderInstance = async (options = { version: 'weekly', libraries: ['places'] }) => {
  // when instance is not initialized
  if (!geocoderInstance) {
    try {
      await getGoogleInstance(options)
      geocoderInstance = new window.google.maps.Geocoder()
    } catch (err) {
      throw new Error(err)
    }
  }

  return geocoderInstance
}

const geocode = async (query, options = {
  defaultAddress: {
    unit: '',
    streetNumber: '',
    streetName: '',
    city: '',
    code: '',
    state: 'ON', // set statically due that autocomplete search is only for Ontario
    country: 'CA', // set statically due that autocomplete search is only for Canada
  },
}) => {
  let geocoder
  try {
    geocoder = await getGeoCoderInstance(options)
  } catch (err) {
    throw new Error(err)
  }

  return new Promise((resolve, reject) => {
    geocoder.geocode(query, (results, status) => {
      if (status === 'OK') {
        const { address_components: addressComponents } = results[0]
        const addressDetails = addressComponents.reduce((acc, curr) => {
          if (curr.types.includes('subpremise')) {
            acc.unit = curr.long_name
          }
          if (curr.types.includes('street_number')) {
            acc.streetNumber = curr.long_name
          }
          if (curr.types.includes('route')) {
            acc.streetName = curr.long_name
          }
          if (
            curr.types.includes('sublocality_level_1') // North york - more accurate
                        || (!acc.city && curr.types.includes('locality')) // Toronto - 2nd level
          ) {
            acc.city = curr.long_name
          }
          if (curr.types.includes('postal_code')) {
            acc.code = curr.long_name
          }
          if (curr.types.includes('administrative_area_level_1')) {
            acc.state = curr.short_name
          }
          if (curr.types.includes('country')) {
            acc.country = curr.short_name
          }

          return acc
        }, options.defaultAddress)

        resolve(addressDetails)
      } else {
        reject(status)
      }
    })
  })
}

export default {
  geocode,
}
