import axios from '../http'
import config from './config'

const PHARMACY_SEARCH_URL = 'https://pharmaguide.ent.northamerica-northeast1.gcp.elastic-cloud.com/api/as/v1/engines/pharmacies/search.json'
const PHARM_SEARCH_API_KEY = 'search-fstnm87ex5fm6ugjxphkmbch'

const enrollment = async (token, userId, pharmacy) => {
  try {
    return await axios.post(`${config.api}pharmacy/onboarding`, {
      pharmacy,
      user: userId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(response => response.data)
  } catch (e) {
    return Promise.reject(e.response)
  }
}

const search = async query => {
  try {
    return await axios.post(PHARMACY_SEARCH_URL, {
      query,
    }, {
      headers: {
        Authorization: `Bearer ${PHARM_SEARCH_API_KEY}`,
      },
    }).then(response => response.data)
  } catch (e) {
    return Promise.reject(e.response)
  }
}

export default {
  enrollment,
  search,
}
